<script setup>
const {currentPage} = useGlobalStore();
const {hpTestimonials} = currentPage.template?.homepage;
const carousel = ref(null);
const settings = {}

const breakpoints = {

  800: {
    itemsToShow: 3.4,
    snapAlign: 'start',
    dir: 'rtl',
  },
}
// onMounted(() => {
//   if (carousel.value) {
//     carousel.value?.updateSlideSize();
//   }
//
// });
// onUpdated(() => {
//   if (carousel.value) {
//     carousel.value?.updateSlideSize();
//   }
// })
</script>
<template>
  <div
      class="testimonials-section section--pad cover"
      :style="`background-image: url('${hpTestimonials?.background?.node?.sourceUrl}.webp')`"
  >
    <span class="text--white text-center title-h2">{{ hpTestimonials?.title }}</span>
    <div class="subtitle text--white text-center"></div>
    <div class="container relative">
      <a class="arrow_bt arrow_bt__left" href="javascript:void(0)" @click="carousel.next();">
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 16 29"
            style="visibility: visible"
            xml:space="preserve"
            class="svg-clean"
        >
          <g>
            <path
                id="Chevron_Right"
                d="M0.4,15.5l13.1,13.1c0.6,0.6,1.5,0.6,2.1,0c0.6-0.6,0.6-1.5,0-2.1l-12-12l12-12c0.6-0.6,0.6-1.5,0-2.1
		c-0.6-0.6-1.5-0.6-2.1,0L0.4,13.5C-0.1,14-0.1,15,0.4,15.5z"
            ></path>
          </g>
        </svg>
      </a>
      <a class="arrow_bt arrow_bt__right" href="javascript:void(0);" @click="carousel.prev();">
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 16 29"
            style="visibility: visible"
            xml:space="preserve"
            class="svg-clean"
        >
          <g>
            <path
                id="Chevron_Right"
                d="M0.4,15.5l13.1,13.1c0.6,0.6,1.5,0.6,2.1,0c0.6-0.6,0.6-1.5,0-2.1l-12-12l12-12c0.6-0.6,0.6-1.5,0-2.1
		c-0.6-0.6-1.5-0.6-2.1,0L0.4,13.5C-0.1,14-0.1,15,0.4,15.5z"
            ></path>
          </g>
        </svg>
      </a>
      <Carousel class="testimonials-slider mx-auto" ref="carousel" :item-to-show="1"
                :wrap-around="false"
                :breakpoints="breakpoints"
                wrap-around dir="rtl">
        <Slide v-for="(item,index) in hpTestimonials?.testimonials" :key="index" class="ml-3">
          <a
              class="testimonials-item "
              :href="item?.videoLink"
              data-fancybox=""

          >
            <div class="testimonials-item__img relative">
              <WpImage :img="item?.image?.node" :set-webp="true"/>
              <div class="overlay d-flex justify-center align-items-center flex-column">
                <div class="play-bt">
                  <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 78 78"
                      style="visibility: visible"
                      xml:space="preserve"
                      class="svg-clean"
                  >
                    <circle class="" cx="39" cy="39.6" r="39" style="opacity: 0.8; fill: rgb(182, 192, 193)"></circle>
                    <path
                        class=""
                        d="M52.5,41.7L33,53.8c-0.4,0.3-0.8,0.4-1.3,0.4c-0.4,0-0.8-0.1-1.2-0.3c-0.8-0.4-1.2-1.2-1.2-2.1V27.4
	c0-0.9,0.5-1.7,1.2-2.1c0.8-0.4,1.7-0.4,2.5,0.1l19.5,12.2c0.7,0.5,1.1,1.2,1.1,2.1C53.6,40.4,53.2,41.2,52.5,41.7z"
                        style="fill: rgb(255, 255, 255)"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <span class="text-center d-block title-h5">{{ item?.title }}</span> </a
          >
        </Slide>

        <template #addons>

          <Pagination/>
        </template>
      </Carousel>
    </div>

    <div class="text-center text-white mt-4">
      <nuxt-link :to="parseLink(hpTestimonials?.moreLink?.url)" class="more-link red-underline red-underline--short">
        {{ hpTestimonials?.moreLink?.title }}
      </nuxt-link>
    </div>
  </div>
</template>
<style lang="scss">
:root {
  --vc-pgn-width: 8px;
  --vc-pgn-height: 8px;
  --vc-pgn-margin: 4px;
  --vc-pgn-border-radius: 50%;
}

.carousel__pagination-button {
  &--active {
    &::after {
      opacity: 1 !important;
    }
  }

  &::after {
    background-color: #fff;
    opacity: 0.5;
  }
}

</style>

